<template>
  <div class="yx-wid">
    <div class="yx-wid banner">
      <img
        v-lazy="item.imgUrl"
        v-for="(item, index) in banners"
        :key="index"
        @click="openBanner(item)"
        :class="{ hover: index === bannerInd }"
      />
      <!-- 快捷banner导航 -->
      <div class="banner-nav">
        <span
          v-for="(item, index) in banners"
          :key="index"
          :class="{ hover: index === bannerInd }"
          @click="tabBanner(item, index)"
        ></span>
      </div>
      <!-- 左右切换 -->
      <div class="banner-prev" @click="tabPrev"></div>
      <div class="banner-next" @click="tabNext"></div>
    </div>
    <!-- 跑马灯 -->
    <div class="yx-wid-12 home-ad">
      <div class="home-ad-icon"></div>
      <div class="home-ad-scroll" v-if="newsList" ref="scorll">
        <div
          class="home-ad-text"
          @mouseout="scrollStar"
          @mouseover="moveScroll"
          ref="scorllWid"
          :style="{
            transform: 'translateX(' + scorllLeftVal + 'px)',
            transition: scrollLeftStar,
          }"
        >
          <div
            class="row"
            v-for="(item, index) in newsList"
            @click="openMessage(item)"
            :key="index"
            v-html="item.scrollContent"
          ></div>
        </div>
      </div>
      <div class="home-ad-more" @click="isLoginTip(viewMessage)">
        {{ $t("more") }}
      </div>
    </div>
    <!-- 马德里竞技俱乐部ad -->
    <div
      v-if="baseInfo && baseInfo[40].configKey"
      class="yx-wid-12 home-ad-img"
      @click="$router.push('/sponsor/madrid')"
    >
      <img :src="baseText('webImg')" />
    </div>
    <!-- APP下载 -->
    <div class="yx-wid-12 home-load">
      <div class="home-title">
        <img src="@/assets/images/title01.png" />
      </div>
      <div class="home-load-flex">
        <div class="flex-left">
          <img
            v-if="baseInfo && baseInfo[41].configKey"
            :src="baseText('webAppLeftImg')"
            class="img"
          />
        </div>
        <div class="flex-right" v-if="baseInfo && baseInfo[22]">
          <div class="load-tab">
            <ul>
              <li
                v-for="(item, index) in loads"
                :key="index"
                @click="loadTab(item, index)"
                :class="{ hover: index === loadInd }"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="load-sec">
            <strong>{{ loads[loadInd].title }}</strong>
            <p v-html="$t(loads[loadInd].sec)"></p>
          </div>
          <div class="load-add" v-show="[0].includes(loadInd)">
            <div class="load-add-code">
              <div>
                <QRCode
                  v-if="baseInfo[22].configValue"
                  :text="baseText('iosApp')"
                  :width="120"
                  :height="120"
                />
              </div>
              <div class="text">
                <span>{{ $t("download.sm") }}</span>
                <p>{{ $t("download.zc") }}iOS</p>
              </div>
            </div>
            <div class="load-add-web">
              <div class="add">
                <a :href="baseText('iosApp')">{{ baseText("iosApp") }}</a>
              </div>
              <div class="text">
                <span>{{ $t("download.zjfw") }}</span>
                <p>{{ $t("download.zjfw-tips") }}</p>
              </div>
            </div>
          </div>
          <div
            class="load-add"
            v-show="[1].includes(loadInd) && baseInfo && baseInfo[21]"
          >
            <div class="load-add-code">
              <div>
                <QRCode
                  v-if="baseInfo[21].configValue"
                  :text="baseText('androidApp')"
                  :width="120"
                  :height="120"
                />
              </div>
              <div class="text">
                <span>{{ $t("download.sm") }}</span>
                <p>{{ $t("download.zc") }}andriod</p>
              </div>
            </div>
            <div class="load-add-web">
              <div class="add">
                <a :href="baseText('androidApp')">{{
                  baseText("androidApp")
                }}</a>
              </div>
              <div class="text">
                <span>{{ $t("download.zjfw") }}</span>
                <p>{{ $t("download.zjfw-tips") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 热门游戏 -->
    <div class="yx-wid-12" v-if="hotsGameList">
      <div class="home-title">
        <img src="@/assets/images/title03.png" />
      </div>
      <div class="home-live-tab">
        <span
          class="live-row"
          :class="{ hover: index === hotsGameInd }"
          v-for="(item, index) in hotsGameList"
          :key="index"
          @click="TabHotsGame(item, index)"
          >{{ item.gameTypeName }}</span
        >
      </div>
      <div class="live-view">
        <div class="live-view-img">
          <img v-lazy="hotsGameList[hotsGameInd].imageUrl" />
        </div>
        <div class="live-view-sec">
          <img
            v-lazy="
              require('@/assets/images/gametitle/' +
                hotsGameList[hotsGameInd].gameType +
                '.png')
            "
            class="title"
          />
          <p>
            {{ hotsGameList[hotsGameInd].gameDescription }}
          </p>
          <ul v-if="hotsGameList && hotsGameList[0].list.length">
            <li
              v-for="(item, index) in hotsGameList[hotsGameInd].list"
              :key="index"
              @click="openGame(item)"
            >
              <div class="icon">
                <img :src="item.icon" class="xz" />
                <img :src="item.icon" class="mr" />
              </div>
              <span>{{ item.apiTitle }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 优质服务 -->
    <div class="yx-wid-12 home-yz">
      <div class="home-title">
        <img src="@/assets/images/title04.png" />
      </div>
      <div class="home-service">
        <ul>
          <li v-for="(item, index) in canvasList" :key="index">
            <div class="icon">
              <canvas-circle
                :id="index"
                :percent="item.ratio"
                :en="item.sec"
              ></canvas-circle>
              <span class="unit">{{ $t(item.unit) }}</span>
            </div>
            <span class="title">{{ $t(item.name) }}</span>
            <p class="sec">{{ item.en }}</p>
          </li>
        </ul>
      </div>
      <div class="home-service-list">
        <div class="row">
          <img src="@/assets/images/five04.png" />
          <span>{{ $t("yzfw.jscqzk") }}</span>
          <p>
            {{ $t("yzfw.jscqzk-sec") }}
          </p>
        </div>

        <div class="row">
          <img src="@/assets/images/five03.png" />
          <span>
            <span>{{ $t("yzfw.hlsszl") }}</span></span
          >
          <p>
            {{ $t("yzfw.hlsszl-sec") }}
          </p>
        </div>

        <div class="row">
          <img src="@/assets/images/five02.png" />
          <span>{{ $t("yzfw.jsaqgl") }}</span>
          <p>
            {{ $t("yzfw.jsaqgl-sec") }}
          </p>
        </div>

        <div class="row">
          <img src="@/assets/images/five01.png" />
          <span> {{ $t("yzfw.sdrnxz") }}</span>
          <p>
            {{ $t("yzfw.sdrnxz-sec") }}
          </p>
        </div>
      </div>
    </div>
    <Notice v-if="notice" @close="notice = false" />
    <Bulletin
      v-if="ismsgShow && newsList"
      :visible.sync="ismsgShow"
      :listData="newsList"
    />
  </div>
</template>

<script>
import Notice from "@/views/notice.vue";
import CanvasCircle from "@/components/Canvas.vue";
import QRCode from "@/components/QRCode.vue";
import Bulletin from "@/components/Bulletin.vue";
import { mapState } from "vuex";
export default {
  components: { CanvasCircle, QRCode, Notice, Bulletin },
  name: "Home",
  data() {
    return {
      ismsgShow: false,
      canvasList: [
        {
          ratio: 60,
          name: "yzfw.pjcksj",
          en: "AVERAGE TIME OF DEPOSIT",
          unit: "yzfw.m",
          sec: "CURRENT SPEED",
        },
        {
          ratio: 80,
          name: "yzfw.hzzfpt",
          en: "PAYMENT PLATFORM PARTNERS",
          unit: "yzfw.j",
          sec: "TOTALLY AMOUNT",
        },
        {
          ratio: 90,
          name: "yzfw.pjqksj",
          en: "AVERAGE TIME OF WITHDRAW",
          unit: "yzfw.m",
          sec: "CURRENT SPEED",
        },
        {
          ratio: 32,
          name: "yzfw.hzyxpt",
          en: "GAMING PROVIDER PARTNERS",
          unit: "yzfw.j",
          sec: "TOTALLY AMOUNT",
        },
      ],
      bannerInd: 0,
      bannerTimer: null,
      loads: [
        {
          name: "IOS APP",
          title: "iOS APP",
          type: 1,
          sec: "views.index.078930-0",
          img: require("@/assets/images/ad01.png"),
          link: "",
        },
        {
          name: "Andriod APP",
          title: "andriod APP",
          type: 2,
          sec: "views.index.078930-1",
          img: require("@/assets/images/ad02.png"),
          link: "",
        },
      ],
      loadInd: 0,
      hotsGameInd: 0,
      newsList: null,
      banners: null,
      canvas: null,
      context: null,
      flash: 0.1,
      scorllLeftVal: 0,
      scrollLeftStar: "all .4s",
      timerText: null,
      scorllWid: 0,
      isScroll: false,
      notice: false,
    };
  },
  computed: {
    ...mapState([
      "navsVal",
      "navsList",
      "isLogin",
      "baseInfo",
      "hotsGameList",
      "userInfo",
    ]),
  },
  watch: {
    navsVal(val) {
      if (!val) return;
      if (val) {
        this.initGameList();
      }
    },
    baseInfo(val) {
      if (val && val.length) {
        this.initBaseInfo();
      }
    },
    // userInfo(val) { //个人信息未填写
    //   if (val) {
    //     this.checkName();
    //   }
    // },
  },
  mounted() {
    this.getTop50MessageList();
    this.getSilde();
    // this.isShow21()
    // this.checkName();//个人信息未填写
  },
  methods: {
    // 检测是否设置姓名
    checkName() {
      if (this.isLogin && this.userInfo && !this.userInfo.realName) {
        this.$confirm(
          this.$t("views.index.078930-2"),
          this.$t("views.index.078930-3"),
          {
            confirmButtonText: this.$t("views.index.078930-4"),
            cancelButtonText: this.$t("views.index.078930-5"),
            type: "none",
          }
        )
          .then(() => {
            this.$router.push("/mine/userInfo");
          })
          .catch(() => {
            console.log(this.$t("views.index.078930-5"));
          });
      }
    },
    // 是否显示21周岁
    isShow21() {
      const dayOld = localStorage.getItem("notice");
      const time = Date.parse(new Date()); //获取当天时间
      const TimeDays = 1000 * 60 * 60 * 24; //设定1天时间
      const isDayTime = time - Number(dayOld); //计算时间
      if (dayOld) {
        this.notice = false;
        if (isDayTime >= TimeDays) {
          localStorage.removeItem("notice", time);
          this.notice = true;
        }
      } else {
        localStorage.setItem("notice", time);
        this.notice = true;
      }
    },

    // 基础信息赋值给data上
    initBaseInfo() {
      if (!this.baseInfo) return;
      this.loads.forEach((item) => {
        if (item.type === 1) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "androidApp"
          ).configValue;
        }
        if (item.type === 2) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "iosApp"
          ).configValue;
        }
      });
    },
    initGameList() {
      const arr = this.navsList.filter(
        (item) => !["0"].includes(item.gameType) && item.gameType
      );
      if (arr) {
        setTimeout(() => {
          this.$store.dispatch("setHotsGameListAc", arr);
        }, 500);
      }
    },
    viewMessage() {
      this.$router.push("/mail");
    },
    openMessage(val) {
      this.$confirm(val.content, val.title, {
        confirmButtonText: this.$t("views.index.078930-6"),
        customClass: "el-pop-wid-message",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (!this.isLogin) return;
          this.$router.push("/mail");
        })
        .catch(() => {
          console.log(11);
        });
    },
    // banner
    getSilde() {
      const params = {
        position: 1,
        device: 1,
      };
      this.$Api.getSilde(params).then((res) => {
        if (res) {
          this.banners = res.slideList;
          this.autoBanner();
        }
      });
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList;
          if (this.newsList && this.newsList.length) {
            // 设定24小时后重新开启公告通知弹窗
            const isDay = localStorage.getItem("messageAuto");
            if (!isDay && this.newsList.length) {
              this.ismsgShow = true;
            }
            if (isDay) {
              const targetDay = 24 * 60 * 60 * 1000; //一天秒数
              const isDYDay = new Date().getTime() - isDay; //获取当前日期是否超过一天
              if (isDYDay > targetDay) {
                localStorage.removeItem("messageAuto");
              }
            }
          }
          this.$nextTick(() => {
            this.scrollInit();
          });
        }
      });
    },
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 跑马灯初始化
    scrollInit() {
      // 获取box宽度
      const dowSocrll = this.$refs.scorll.clientWidth;
      this.scorllWid = this.$refs.scorllWid.clientWidth;
      const domClone = this.$refs.scorllWid;
      this.isScroll = this.scorllWid > dowSocrll ? true : false;
      if (this.isScroll) {
        let dom = domClone.cloneNode(true);
        domClone.appendChild(dom);
        this.scrollStar();
      }
    },
    scrollStar() {
      if (!this.isScroll) return;
      this.timerText = setInterval(() => {
        const valNumber = 5;
        this.scorllLeftVal -= valNumber;
        if (this.scorllLeftVal <= -this.scorllWid) {
          this.scorllLeftVal = 0;
          this.scrollLeftStar = "none";
          setTimeout(() => {
            this.scrollLeftStar = "all .4s";
          }, 50);
        }
      }, 120);
    },
    tabLive(val, index) {
      this.liveInd = index;
    },
    TabHotsGame(val, index) {
      this.hotsGameInd = index;
    },
    autoBanner() {
      this.bannerTimer = setInterval(() => {
        this.bannerInd++;
        if (this.bannerInd >= this.banners.length) {
          this.bannerInd = 0;
        }
      }, 5000);
    },
    tabBanner(val, index) {
      clearInterval(this.bannerTimer);
      this.bannerInd = index;
      this.autoBanner();
    },
    openBanner(val) {
      if (val.activityId != null) {
        this.$router.push("/activity/activityLandPage?type=" + val.activityId);
      }
    },
    tabPrev() {
      clearInterval(this.bannerTimer);
      if (this.bannerInd === 0) {
        this.bannerInd = this.banners.length;
      }
      this.bannerInd--;
      this.autoBanner();
    },
    tabNext() {
      clearInterval(this.bannerTimer);
      this.bannerInd++;
      if (this.bannerInd === this.banners.length) {
        this.bannerInd = 0;
      }
      this.autoBanner();
    },
    loadTab(val, index) {
      this.loadInd = index;
    },
    // 打开游戏
    openGame(val) {
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm(
          this.$t("views.index.078930-7"),
          this.$t("views.index.078930-3"),
          {
            confirmButtonText: this.$t("views.index.078930-4"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.moveScroll);
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 700px;
  position: relative;
  overflow: hidden;

  &:hover {
    .banner-next,
    .banner-prev {
      display: block;
    }
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(0.85);
    top: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.8s;

    &.hover {
      opacity: 1;
      transform: translateX(-50%) scale(1);
      z-index: 1;
    }
  }

  &-nav {
    white-space: nowrap;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0 3px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
      }

      &::before {
        width: 50%;
        height: 50%;
        background: #666;
        display: block;
      }

      &.hover {
        &::before {
          background: #2599f8;
        }

        &::after {
          width: 70%;
          height: 70%;
          border: 1px solid #2599f8;
        }
      }
    }
  }
}

.banner-prev,
.banner-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: none;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-left-color: white;
    border-top-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background: #2599f8;
  }
}

.banner-next {
  right: 0;

  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

.home-ad {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #d1d5f5;
  padding: 0 10px 0 18px;
  background: linear-gradient(180deg, #fff, #e7f0ff);
  box-shadow: 0 4px 8px rgba(136, 149, 205, 0.2),
    inset 0 0 12px rgba(255, 255, 255, 0.72);
  margin-top: 31px;

  &-icon {
    width: 53px;
    height: 63px;
    background: url("@/assets/images/icon-ad.png") no-repeat center center;
    background-size: cover;
  }

  &-scroll {
    flex: 1;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    color: #788b9d;
    position: relative;
  }

  &-text {
    display: inline-block;
    white-space: nowrap;
    // width: 100%;
    // animation: amin 10s linear infinite;

    .row {
      display: inline-block;
      vertical-align: middle;
      margin-right: 60px;
      cursor: pointer;
    }
  }

  &-more {
    width: 75px;
    height: 26px;
    line-height: 26px;
    margin-left: 10px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #2599f8;
    border-radius: 13px;
    color: #2599f8;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: #2599f8;
      color: white;
    }
  }

  &-img {
    margin-top: 36px;
    cursor: pointer;

    img {
      transition: all 0.4s;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

// @keyframes amin {
//   0% {
//     transform: translateX(0);
//   }

//   100% {
//     transform: translateX(-100%);
//   }
// }

.home-title {
  width: 100%;
  margin: 48px 0 32px;

  img {
    width: 100%;
  }
}

.home-load-flex {
  display: flex;
  align-items: center;

  .img {
    width: 880px;
  }

  .flex-left {
    flex: 1;

    .img {
      width: 115%;
      margin-left: -15%;
    }
  }

  .flex-right {
    background: url("@/assets/images/load-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 456px;
    height: 580px;
    box-shadow: 0 6px 20px 3px rgba(201, 211, 237, 0.5);
    border-radius: 24px;
    flex: none;
    padding: 36px 24px;

    .load-tab {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      li {
        width: 132px;
        height: 44px;
        background: url("@/assets/images/tab-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #788b9d;
        font-size: 16px;
        cursor: pointer;
        margin: 0 10px;

        &.hover {
          color: white;
          background: url("@/assets/images/tab-bg1.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .load-sec {
      padding: 0 10px;

      strong {
        font-size: 40px;
        margin-top: 32px;
        display: block;
      }

      p {
        line-height: 28px;
        font-size: 18px;
        margin-top: 12px;
        text-align: justify;
      }
    }

    .load-add {
      display: flex;
      align-items: center;
      padding: 24px 10px 0;

      &-code,
      &-web {
        background: #f4faff;
        border: 1px solid #fff;
        border-radius: 12px;
        padding: 23px 31px 12px;
        height: 224px;
        width: 182px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          text-align: center;
          line-height: 22px;

          span {
            font-size: 16px;
            margin-top: 20px;
            display: block;
          }

          p {
            font-size: 12px;
            margin-top: 2px;
            white-space: normal;
            word-break: break-all;
          }
        }
      }

      &-web {
        margin-left: 22px;

        .add {
          width: 120px;
          height: 120px;

          a {
            color: #2599f8;
            word-wrap: break-word;
          }
        }
      }

      &-code {
        .img {
          width: 120px;
        }
      }
    }
  }
}

.load-add-pc {
  padding: 0 10px;
  margin-top: 32px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    width: 120px;
    height: 168px;
    background: #f0f6ff;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 60%;
    }

    .btn {
      background: url("@/assets/images/tab-bg1.png") no-repeat;
      line-height: 22px;
      height: 22px;
      width: 68px;
      font-size: 12px;
      background-size: 100% 100%;
      cursor: pointer;
      box-shadow: 0 2px 5px #5da7eb;
      border-radius: 11px;
      color: white;
      text-align: center;
      margin-top: 12px;
    }
  }
}

.home-live-tab {
  display: flex;
  align-items: center;
  justify-content: center;

  .live-row {
    width: 120px;
    height: 40px;
    background: url("@/assets/images/tab-bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #788b9d;
    box-shadow: 0 4px 8px rgba(184, 208, 255, 0.3);
    border-radius: 30px;
    margin: 0 8px;
    cursor: pointer;

    &.hover {
      background: url("@/assets/images/tab-bg1.png") no-repeat;
      background-size: 100% 100%;
      color: white;
    }
  }
}

.live-box {
  background: url("@/assets/images/live-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 6px 20px rgba(201, 211, 237, 0.5);
  border-radius: 24px;
  padding: 24px;
  margin-top: 24px;
}

.live-now {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex: 1;
    overflow: hidden;

    dl {
      display: flex;
      align-items: center;

      dd {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 108px;
        height: 40px;
        padding: 8px 12px;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(210, 183, 156, 0.15);
        background: linear-gradient(
            0deg,
            hsla(30, 37%, 72%, 0.1),
            hsla(30, 37%, 72%, 0.1)
          ),
          #fff;
        background: #e9f5fe;
        border: 1px solid #2599f8;
        white-space: nowrap;
        box-shadow: 0 4px 8px rgba(23, 156, 255, 0.15);

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .right {
    position: relative;

    input {
      width: 156px;
      height: 33px;
      border-radius: 12px;
      background-image: url("@/assets/images/glass.png");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 12px;
      border: 1px solid transparent;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 12px 8px 36px;
      transition: all 0.3s;
      z-index: 1;
      color: #48535e;
      background-color: #fff;

      &::placeholder {
        color: #c9d1d8 !important;
      }
    }
  }
}

.live-scroll {
  margin-top: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 144px;

  &-list {
    white-space: nowrap;
    width: 100%;
  }

  &-box {
    display: inline-block;
    vertical-align: middle;
    width: 320px;
    height: 112px;
    margin-right: 16px;
    filter: drop-shadow(0 8px 20px rgba(204, 221, 219, 0.4));
  }

  &-row {
    width: 320px;
    height: 112px;
    box-sizing: border-box;
    cursor: pointer;
    background: #e9eff8;
    border-radius: 12px;
    background: #fff;
    border: 2px solid #fff;
    padding: 44px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      border: 2px solid #2599f8;
    }

    &.hover {
      background: #f0f6ff;
      border: 2px solid #2599f8;

      &::before {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-top-color: #2599f8;
        border-bottom: none;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .live-row-title {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      height: 20px;
      line-height: 20px;
      font-weight: 500;
      justify-content: center;
      padding: 0;
      margin-bottom: 8px;
      color: #30383f;
    }

    .left,
    .right {
      text-align: center;

      .img {
        flex: 0 0 44px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

        img {
          width: 84%;
        }
      }
    }

    .center {
      padding: 0 30px;
      text-align: center;

      .vs {
        display: flex;
        height: 24px;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 5px;
        text-overflow: -5px;
      }

      .text {
        font-size: 12px;
      }

      .vs-open {
        display: flex;
        align-items: center;
        justify-content: center;

        .vs-row {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 24px;
          height: 24px;
          margin: 0 3px;

          &.one {
            background-image: url("@/assets/images/game-icon-01.png");
          }

          &.two {
            background-image: url("@/assets/images/game-icon-03.png");
          }

          &:hover {
            &.one {
              background-image: url("@/assets/images/game-icon-02.png");
            }

            &.two {
              background-image: url("@/assets/images/game-icon-04.png");
            }
          }
        }
      }
    }
  }
}

.live-video {
  display: flex;
  align-items: center;

  &-left {
    height: 650px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 24px;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: white;
    padding: 12px 0;

    .name {
      color: #30383f;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 6px solid #d3ebfe;
        background: white;
        border-radius: 100%;
        margin: 0 8px;
      }
    }

    .vs-number {
      font-size: 36px;
      letter-spacing: 5px;
      color: #30383f;
      font-weight: bold;
      margin: 0 10px;
    }
  }

  &-right {
    width: 308px;
    display: flex;
    flex-direction: column;
    height: 650px;
    background: #f1f3f5;
    border-radius: 12px;
    border: 1px solid #fff;

    .chat-list {
      flex: 1;
      position: relative;
      overflow: hidden;

      &-no {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .chat-send {
      background: white;
      display: flex;
      align-items: center;
      padding: 12px;

      .send {
        background: #f1f3f5;
        border: 1.5px solid #fff;
        height: 36px;
        line-height: 1;
        border-radius: 12px;
        background-image: url("@/assets/images/fj.png");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 250px center;
        padding: 8px 36px 8px 8px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
    }
  }
}

.live-video-data {
  display: flex;
  align-items: flex-end;
  background: white;
  border-radius: 12px;
  padding: 10px 24px;
  margin-top: 20px;

  .left {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .data-row {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &-title {
      font-size: 12px;
      border-bottom: 1px solid #e4eaff;
      color: #30383f;
    }

    &-list {
      display: flex;
      align-items: center;
      margin-top: 4px;

      li {
        flex: 1;
        height: 44px;
        padding: 4px 0;
        background: #fff;
        border: 1px solid #ebedf0;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        span {
          color: #788b9d;
          font-size: 12px;
        }

        p {
          color: #4ab06a;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          em {
            border-radius: 2px;
            margin-right: 2px;
            border: 4px solid transparent;
            border-top: 6px solid #4ab06a;
            border-bottom: none;
          }

          &.red {
            color: #e95b5b;

            em {
              border: 4px solid transparent;
              border-bottom: 6px solid #e95b5b;
              border-top: none;
            }
          }
        }
      }
    }
  }

  .right {
    margin-left: 30px;
  }
}

.live-video-play {
  flex: 1;
  background: #30383f;
  border-radius: 12px;
  margin-top: 24px;
}

.live-view {
  position: relative;
  display: flex;
  justify-content: end;

  &-img {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-42%);
    z-index: 3;

    img {
      width: 700px;
      height: 590px;
    }
  }

  &-sec {
    width: 700px;
    min-height: 454px;
    position: relative;
    margin-top: 35px;
    background-color: none;
    border: none;
    box-shadow: 0 6px 20px 3px rgba(201, 211, 237, 0.5);
    background: url("@/assets/images/live-bg01.png") no-repeat 50%/100%;
    background-size: 100% 100%;
    border-radius: 24px;
    z-index: 2;
    padding-left: 180px;
    padding-top: 60px;
    padding-bottom: 30px;

    .title {
      height: 116px;
    }

    p {
      padding-right: 2rem;
      text-align: justify;
      min-height: 72px;
      margin-top: 11px;
      line-height: 1.5;
      font-size: 16px;
    }

    ul {
      display: flex;
      align-items: center;
      margin-top: 10px;
      flex-wrap: wrap;

      li {
        margin: 0 12px 8px 0;

        .icon {
          width: 58px;
          height: 58px;
          margin: 0 4px;
          border-radius: 12px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(180deg, white, #e8ebfa);
          box-shadow: 0 4px 8px 0 rgba(112, 146, 215, 0.14);
          border-bottom: 3px solid white;
          cursor: pointer;

          &:hover {
            background-image: linear-gradient(180deg, #93edfc, #468cf7);
            border-bottom: 1px solid #468cf7;

            .mr {
              display: none;
            }

            .xz {
              display: block;
            }
          }

          img {
            width: 80%;

            &.mr {
              filter: invert(30%);
            }
          }

          .xz {
            display: none;
          }
        }

        span {
          display: block;
          text-align: center;
          margin-top: 6px;
        }
      }
    }
  }
}

.home-service {
  ul {
    display: flex;
    align-items: center;
  }

  li {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;

    .icon {
      position: relative;
      width: 160px;
      height: 137px;
      margin: 7px auto 0;
      background: url("@/assets/images/loading-bg.png") no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px 0 0 0;
    }

    .title {
      margin-top: 28px;
      margin-bottom: 2px;
      font-size: 18px;
      font-weight: 500;
      color: #30383f;
      line-height: 25px;
    }

    .sec {
      line-height: 15px;
      font-size: 12px;
      color: #788b9d;
      font-weight: 400;
    }

    .unit {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.home-service-list {
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 64px;

  .row {
    position: relative;
    width: 580px;
    height: 140px;
    border-radius: 24px;
    background: url("@/assets/images/service-bg.png") no-repeat 50%/100%;
    padding: 24px 24px 0 120px;
    margin-top: 24px;
    box-shadow: 0 10px 20px 0 rgba(205, 210, 221, 0.6);

    img {
      position: absolute;
      width: 88px;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
    }

    span {
      font-weight: 500;
      font-size: 18px;
      color: #30383f;
      line-height: 25px;
    }

    p {
      font-weight: 400;
      line-height: 22px;
      margin-top: 6px;
      font-size: 16px;
      color: #788b9d;
    }
  }
}

.home-yz {
  margin-top: 88px;
}
</style>
